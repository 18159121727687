import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { cdaStore, parceriaCupomHistoricoStore, parceriaCupomStore, parceriaStore, userActionStore, userToolStore, analyticsStore, } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import _ from 'lodash';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import FiltroPorTabela from '@/components/FiltrosPorTabela.vue';
import ParceriaCupomCreateComponent from '@/components/ParceriaCupomCreateComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newParceria } from '@/interfaces/parceria';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let ParceriaCupomCrudComponent = class ParceriaCupomCrudComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.tabelaRef = null;
        this.parceria = {
            data_inicio: '',
            data_termino: '',
            nome: 'Todos',
            descricao: '',
            company_id: 0,
            id: 0,
        };
        this.movidoParaCda = { id: -1 };
        this.search = '';
        this.dialogCriarCupom = false;
        this.historicoItems = [];
        this.historicoLoading = false;
        this.headers = [
            { text: '', value: 'selected', width: '20px', show: true },
            {
                text: 'Criado em',
                value: 'created_at',
                show: true,
                table: 'parceria_cupom',
                type: 'timestamp',
            },
            {
                text: 'Nome',
                value: 'nome',
                show: true,
                table: 'parceria_cupom',
                type: 'str',
            },
            {
                text: 'Idade',
                value: 'idade',
                show: true,
                table: 'parceria_cupom',
                type: 'int',
            },
            {
                text: 'Telefone',
                value: 'telefone',
                show: true,
                table: 'parceria_cupom',
                type: 'str',
            },
            // @ts-ignore
            { text: `${this.getNomeCDA()} responsável`, value: 'cda_nome', show: true },
            { text: 'Status atual', value: 'status_id', show: true },
            { text: 'Ultimo contato', value: 'ultimo_contato', show: true },
            { text: 'Tipo', value: 'interesse_id', show: true },
            {
                text: 'Campanha',
                value: 'campaign',
                show: true,
                table: 'parceria_cupom',
                type: 'str',
            },
            { text: '', value: 'actions', show: true },
            // { text: "", value: "", show: true },
            { text: '', value: 'data-table-expand', show: true },
        ];
        this.headersObservacoes = [
            { text: 'Data do contato', value: 'created_at' },
            { text: 'Status', value: 'status_id' },
            { text: 'Observações', value: 'observacao' },
        ];
        this.statusHeaders = [
            { text: 'Status', value: 'status_id' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.interesseHeaders = [
            { text: 'Interesse', value: 'interesse_id' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.statusItems = [];
        this.statusSelected = [];
        this.interesseItems = [];
        this.interesseSelected = [];
        this.parceriaCuponsItems = [];
    }
    get totals() {
        return analyticsStore.totals;
    }
    get headersFiltered() {
        return _.filter(this.headers, (item) => item.show);
    }
    get totalSelecionado() {
        const cupons = analyticsStore.items.filter((item) => {
            return item.selected;
        });
        return cupons.length;
    }
    get parceriasOptions() {
        const obj = { ...newParceria(), id: 0 };
        obj.nome = 'Todos';
        const options = [...parceriaStore.parcerias];
        options.unshift(obj);
        return options;
    }
    get userActionDistribuirCuponsEntreCdasAllow() {
        return userActionStore.distribuirCuponsEntreCdasAllow;
    }
    get userActionExcluirCuponsEmLoteAllow() {
        return userActionStore.excluirCuponsEmLote;
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_delete;
        }
    }
    get cdaOptions() {
        const cdas = cdaStore.cdasAtivos;
        // @ts-ignore
        cdas.unshift({
            id: 0,
            // @ts-ignore
            full_name: this.getNomeCDA() + 's ALEATÓRIOS',
        });
        return cdas;
    }
    getStatusPorData(item) {
        // @ts-ignore
        const status = _.find(this.parceriaCupomStatusOptions(), (i) => i.id == item.status_id).name;
        const dataAtual = new Date();
        dataAtual.setHours(dataAtual.getHours() + 2);
        function verificaSeCupomAgendouParaHoje(date_verif) {
            const date1 = new Date();
            const year1 = date1.getFullYear();
            const month1 = date1.getMonth();
            const day1 = date1.getDate();
            const year2 = date_verif.getFullYear();
            const month2 = date_verif.getMonth();
            const day2 = date_verif.getDate();
            return year1 === year2 && month1 === month2 && day1 === day2;
        }
        if (status == 'Agendado' && item.ultimo_agendamento) {
            const dataAgendamento = new Date(item.ultimo_agendamento);
            if (dataAtual > dataAgendamento) {
                return 'Não compareceu';
            }
            else if (status == 'Agendado' && verificaSeCupomAgendouParaHoje(dataAgendamento)) {
                return 'Agendado para hoje';
            }
        }
        return status;
    }
    async showParceriaCupomHistorico(item) {
        this.historicoItems = [];
        this.historicoLoading = true;
        this.historicoItems =
            await parceriaCupomHistoricoStore.getParceriaCupomHistoricoByParceriaCupomId(item.item.id);
        this.historicoLoading = false;
    }
    showFiltroPorTabela() {
        // @ts-ignore
        const form = this.$refs.filtroPorTabela;
        form.show('aluno');
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-cupons-edit', params: { id } });
    }
    async criarCupom() {
        this.dialogCriarCupom = true;
    }
    async onCancel() {
        this.loading = true;
        this.dialogCriarCupom = false;
        await this.buscar();
        this.loading = false;
    }
    openAluno(alunoId) {
        const rota = this.$router.resolve({
            name: 'main-alunos-edit',
            params: { id: alunoId },
        });
        window.open(rota.href, '_blank');
    }
    openProspex(prospexId) {
        const rota = this.$router.resolve({
            name: 'main-prospexs-edit',
            params: { id: prospexId },
        });
        window.open(rota.href, '_blank');
    }
    desvincularCDA(parceriaCupomId) {
        // @ts-ignore
        const tipo = this.getNomeCDA();
        this.$swal({
            title: `Confirma o desvinculo com o ${tipo}?`,
            text: `Caso o cupom seja desvinculado ele ficará novamente disponível para ser vinculado a outro ${tipo}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await parceriaCupomStore.desvincularParceriaCupomCda(parceriaCupomId);
                const item = _.find(analyticsStore.items, (i) => i.id == parceriaCupomId);
                item.cda_id = null;
                item.cda_nome = null;
            }
        });
    }
    async moverPara() {
        this.$swal({
            title: 'Confirma a distribuição dos cupons?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cupons = analyticsStore.items.filter((item) => {
                    if (item.selected) {
                        item.selected = false;
                        return item;
                    }
                });
                if (cupons.length === 0) {
                    this.$swal({
                        text: 'Para distribuir você deve selecionar ao menos um cupom!',
                        icon: 'error',
                    });
                    return 1;
                }
                const payload = {
                    cupons,
                    cda_id: this.movidoParaCda,
                };
                await cdaStore.distribuirCupons(payload);
                await this.buscar();
            }
        });
    }
    async excluirLote() {
        this.$swal({
            title: 'Confirma a exclusão dos cupons?',
            text: 'Esta operação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cupons = analyticsStore.items.filter((item) => {
                    if (item.selected) {
                        item.selected = false;
                        return item;
                    }
                });
                if (cupons.length === 0) {
                    this.$swal({
                        text: 'Para distribuir você deve selecionar ao menos um cupom!',
                        icon: 'error',
                    });
                    return 1;
                }
                const payload = {
                    cupons
                };
                await cdaStore.excluirCuponsLote(payload);
                await this.buscar();
            }
        });
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do cupom?',
            text: 'Após exclusão o cupom não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await parceriaCupomStore.deleteParceriaCupom(id);
                await this.buscar();
                this.loading = false;
            }
        });
    }
    async created() {
        this.$nextTick(() => {
            // @ts-ignore
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async doSearchItems(queryIn) {
        return await parceriaCupomStore.getParceriaCuponsQuery(queryIn);
    }
    async buscar() {
        const filters = {};
        if (this.propParceriaId) {
            filters['parceria_cupom.parceria_id'] = {
                type: 'int',
                value: [this.propParceriaId],
            };
        }
        if (this.propCdaId) {
            filters['parceria_cupom.cda_id'] = {
                type: 'int',
                value: [this.propCdaId],
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsParceriaCupons({
            parceriaId: this.propParceriaId,
            cdaId: this.propCdaId,
        });
    }
    async mounted() {
        if (!this.propParceriaId) {
            const column = { text: 'Parceria', value: 'parceria_nome', show: true };
            this.headers.splice(1, 0, column);
        }
        this.loading = true;
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(parceriaCupomStore.getParceriaCuponsQuery);
        analyticsStore.setHeaders(this.headers);
        this.buscar();
        await cdaStore.getCdas();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: null })
], ParceriaCupomCrudComponent.prototype, "propParceriaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], ParceriaCupomCrudComponent.prototype, "propCdaId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ParceriaCupomCrudComponent.prototype, "propModoMigrar", void 0);
ParceriaCupomCrudComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            DateFieldComponent,
            FiltroPorTabela,
            ImprimirTabelaComponent,
            LogShowComponent,
            OpenNewTabComponent,
            ParceriaCupomCreateComponent,
            SaveButton,
            SearchEntidadeComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
        },
    })
], ParceriaCupomCrudComponent);
export default ParceriaCupomCrudComponent;
