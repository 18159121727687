import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { parceriaStore, userActionStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import ParceriaAcaoCrudComponent from '@/components/ParceriaAcaoCrudComponent.vue';
import ParceriaCupomCrudComponent from '@/components/ParceriaCupomCrudComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newParceria } from '@/interfaces/parceria';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.tab = 'tab_dados_cadastrais';
        this.entityObject = null;
        this.search = '';
        this.data_inicio = null;
        this.data_termino = null;
        this.parceriaAcaoHeaders = [
            { text: 'Ação', value: 'acao_id' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Data/Hora', value: '' },
            { text: 'Executores', value: '' },
        ];
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    baixarQrcode() {
        // @ts-ignore
        const qrcodeData = this.$refs.qrcode.$el.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = qrcodeData;
        link.download = 'qrcode.png';
        link.click();
    }
    async flyer() {
        const res = await parceriaStore.getParceriaFlyer(this.entityObject.id);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                // @ts-ignore
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'flyer.jpg';
                a.click();
            }
        }
    }
    async cartaz() {
        const res = await parceriaStore.getParceriaCartaz(this.entityObject.id);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                // @ts-ignore
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'cartaz.jpg';
                a.click();
            }
        }
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.data_inicio = this.data_inicio + 'T04:00:00.000Z';
        this.entityObject.data_termino = this.data_termino + 'T04:00:00.000Z';
        if (this.id) {
            const result = await parceriaStore.updateParceria(this.entityObject);
        }
        else {
            const result = await parceriaStore.createParceria(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await parceriaStore.getParceria(this.id);
            this.data_inicio = this.entityObject.data_inicio.substr(0, 10);
            this.data_termino = this.entityObject.data_termino.substr(0, 10);
        }
        else {
            this.entityObject = newParceria();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            OcorrenciaComponent,
            DateFieldComponent,
            LogShowComponent,
            ClickableCard,
            ParceriaAcaoCrudComponent,
            ParceriaCupomCrudComponent,
        },
    })
], Create);
export default Create;
