var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filtro-por-tabela',{ref:"filtroPorTabela",attrs:{"propTableName":"list_parceria_cupom","propItems":_vm.headers}}),_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.dialogCriarCupom),callback:function ($$v) {_vm.dialogCriarCupom=$$v},expression:"dialogCriarCupom"}},[_c('parceria-cupom-create-component',{attrs:{"propParceriaId":parseInt(_vm.propParceriaId, 0)},on:{"on-cancel":function($event){return _vm.onCancel()}}})],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Cupons")])]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.allowCreate && !_vm.propModoMigrar)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.criarCupom()}}},[_vm._v("Cadastrar")]):_vm._e()],1)],1)],1),_c('v-row',[(_vm.totals.por_status)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por status","prop-items":_vm.totals.por_status,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomStatusOptions(), function (i) { return i.id == item.id; }).name)+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.totals.por_parceria && !_vm.propParceriaId)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por parceria","prop-items":_vm.totals.por_parceria,"prop-fixed-header":true,"prop-height":'350'}})],1):_vm._e()],1),_c('v-row',[(_vm.totals.por_tipo)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por tipo","prop-items":_vm.totals.por_tipo,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == item.id; }).name)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e(),(_vm.totals.por_campanha)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por campanha","prop-items":_vm.totals.por_campanha,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e(),(_vm.totals.por_cda)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":("Por " + (_vm.getNomeCDA())),"prop-items":_vm.totals.por_cda,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propExpandable":true,"funcExpandable":_vm.showParceriaCupomHistorico},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [(!item.cda_id || _vm.propModoMigrar)?_c('v-checkbox',{attrs:{"dense":"","hide-details":""},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}):_vm._e()]}},{key:"item.parceria_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.parceria_nome)),"propObjectId":item.parceria_id,"propToolName":"Parcerias"}})]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome)),"propObjectId":item.id,"propToolName":"Cupons"}})]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.cda_nome",fn:function(ref){
var item = ref.item;
return [(item.cda_nome)?_c('span',[_vm._v(" "+_vm._s(item.cda_nome)+" "),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.desvincularCDA(item.id)}}},[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item.interesse_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == value; }).name)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [(!!item.entidade_obj)?_c('div',_vm._l((item.entidade_obj),function(entidade_obj){return _c('div',[(entidade_obj.aluno_id)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openAluno(entidade_obj.aluno_id)}}},[_vm._v(" aluno ")]):(entidade_obj.prospex_id)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openProspex(entidade_obj.prospex_id)}}},[_vm._v(" prospex ")]):_vm._e()],1)}),0):_c('div',[_vm._v(" "+_vm._s(_vm.getStatusPorData(item))+" ")])]}},{key:"item.ultimo_contato",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.cda",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.full_name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.allowDelete && (!item.prospex_id || !item.aluno_id))?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-1 pa-3",staticStyle:{"border":"1px solid gray","border-radius":"16px"}},[_c('v-data-table',{attrs:{"items":_vm.historicoItems,"headers":_vm.headersObservacoes,"loading":_vm.historicoLoading,"dense":"","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomStatusOptions(), function (i) { return i.id == item.status_id; }).name)+" ")]}}],null,true)})],1)])]}}],null,true)})],1)],1),_c('v-row',[(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-col',{attrs:{"cols":"9"}},[_c('v-autocomplete',{attrs:{"label":("Distribuir cupons para " + (this.getNomeCDA())),"items":_vm.cdaOptions,"item-text":"full_name","item-value":"id","return-object":""},model:{value:(_vm.movidoParaCda),callback:function ($$v) {_vm.movidoParaCda=$$v},expression:"movidoParaCda"}})],1):_vm._e(),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!(_vm.movidoParaCda.id >= 0) || _vm.cdaOptions.length <= 1},on:{"click":_vm.moverPara}},[_vm._v("Mover")])],1):_vm._e(),(_vm.userActionExcluirCuponsEmLoteAllow)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.cdaOptions.length <= 1},on:{"click":_vm.excluirLote}},[_vm._v("Excluir selecionados")])],1):_vm._e()],1),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('span',[_vm._v(" Total de cupons selecionados: "+_vm._s(_vm.totalSelecionado)+" ")]):_vm._e(),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-row',[_c('v-col',[(_vm.cdaOptions.length <= 1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Para distribuir cupons você precisa cadastrar ao menos um "+_vm._s(_vm.getNomeCDA()))]):_vm._e()])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }