var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Cadastrar cupom")])]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '6'}},[_c('v-autocomplete',{attrs:{"label":"Parceria","items":_vm.parcerias,"item-text":"nome","error-messages":_vm.parceriaSelectedErrors,"return-object":""},on:{"change":_vm.removeErrosParceria},model:{value:(_vm.parceriaSelected),callback:function ($$v) {_vm.parceriaSelected=$$v},expression:"parceriaSelected"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '6'}},[_c('v-autocomplete',{attrs:{"label":"Para quem é o curso?","items":_vm.parceriaCupomParaQuemCursoOptions(),"item-text":"nome","item-value":"id","error-messages":_vm.paraQuemCursoErrors},on:{"change":_vm.removeErrosParceria},model:{value:(_vm.paraQuemCurso),callback:function ($$v) {_vm.paraQuemCurso=$$v},expression:"paraQuemCurso"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.objetivoFazerIdiomaOptions(),"label":"Objetivo em fazer um idioma","item-text":"nome","item-value":"id","clearable":""},model:{value:(_vm.entityObject.prospex_objetivo_fazer_idioma_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "prospex_objetivo_fazer_idioma_id", $$v)},expression:"entityObject.prospex_objetivo_fazer_idioma_id"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.dificuldadeAprenderIdioma(),"label":"Dificuldade em aprender um idioma","item-text":"name","item-value":"id","clearable":""},model:{value:(_vm.entityObject.dificuldades_aprender_ingles_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "dificuldades_aprender_ingles_id", $$v)},expression:"entityObject.dificuldades_aprender_ingles_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '2'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","error-messages":errors},model:{value:(_vm.entityObject.nome),callback:function ($$v) {_vm.$set(_vm.entityObject, "nome", $$v)},expression:"entityObject.nome"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '2'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Telefone","vid":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"Telefone","type":"text","error-messages":errors},on:{"change":_vm.validateTelefone},model:{value:(_vm.entityObject.telefone),callback:function ($$v) {_vm.$set(_vm.entityObject, "telefone", $$v)},expression:"entityObject.telefone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '2'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Idade","vid":"idade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"label":"Idade","type":"number","min":"1","error-messages":errors},model:{value:(_vm.entityObject.idade),callback:function ($$v) {_vm.$set(_vm.entityObject, "idade", $$v)},expression:"entityObject.idade"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '2'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"E-mail","vid":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","type":"email"},model:{value:(_vm.entityObject.email),callback:function ($$v) {_vm.$set(_vm.entityObject, "email", $$v)},expression:"entityObject.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '2'}},[_c('v-autocomplete',{attrs:{"items":_vm.parceriaCupomTipoOptions(),"item-text":"name","item-value":"id","label":"Tipo","clearable":""},model:{value:(_vm.entityObject.interesse_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "interesse_id", $$v)},expression:"entityObject.interesse_id"}})],1),_c('v-col',{attrs:{"cols":1}},[_c('save-button',{ref:"saveButton",attrs:{"prop-show-voltar":false,"disabled":invalid},on:{"submit":function($event){return _vm.submit()}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"dense":"","footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.interesse_id",fn:function(ref){
            var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == value; }).name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.deleteItem(item.id, _vm.items.indexOf(item.id))}}},[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" > ")])],1)],1),(_vm.parceriaCuponsNumeroIgual.length > 0)?_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Cupons encontrados com o mesmo número de telefone")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersRepetidos,"items":_vm.parceriaCuponsNumeroIgual,"dense":"","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
            var item = ref.item;
return [_c('entidade-link-component',{attrs:{"propEntidadeId":item.id,"propEntidadeNome":item.nome,"propEntidadeTipo":"cupom"}})]}},{key:"item.interesse_id",fn:function(ref){
            var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == value; }).name)+" ")]):_vm._e()]}}],null,true)})],1)],1):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v("voltar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }