import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { parceriaStore, userActionStore, parceriaAcaoStore, mainStore, } from '@/store';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import _ from 'lodash';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let ParceriaAcaoCrudComponent = class ParceriaAcaoCrudComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.acaoSelected = [];
        this.entidadeSelected = null;
        this.dataAcao = null;
        this.headers = [
            { text: 'Ação', value: 'acao_id' },
            { text: 'Executor', value: 'parceria_acao_colaborador' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Data de execução', value: 'data' },
            { text: 'Horário de inicio', value: 'horario_inicio' },
            { text: 'Horário de fim', value: 'horario_fim' },
            { text: 'Status', value: 'status_id' },
            { text: '', value: 'actions' },
        ];
        this.items = [];
        this.colaboradores = [];
        this.editedItem = {};
        this.descricao = '';
        this.loading = false;
        this.horario_inicio = null;
        this.horario_fim = null;
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    ajustaHora(val, inicio) {
        if (val == '') {
            return;
        }
        const tempHora = val.replace(':', '');
        let horaFinal = tempHora;
        if (tempHora.length < 4) {
            horaFinal = tempHora + '0'.repeat(4 - tempHora.length);
        }
        if (inicio) {
            this.horario_inicio = horaFinal.replace(/^(\d{2})(\d{2}).*/, '$1:$2');
        }
        else {
            this.horario_fim = horaFinal.replace(/^(\d{2})(\d{2}).*/, '$1:$2');
        }
    }
    async onDialog(val) {
        return val || this.close();
    }
    async adicionarColaborador() {
        if (this.entidadeSelected.id) {
            this.colaboradores.push({
                colaborador_id: this.entidadeSelected.id,
                nome: this.entidadeSelected.nome,
            });
            this.entidadeSelected = null;
            // @ts-ignore
            this.$refs.searchEntidadeComponent.selectedEntidade = {};
        }
    }
    async initialize() {
        this.loading = true;
        this.items = await parceriaAcaoStore.getParceriaAcoes(this.propParceriaId);
        this.loading = false;
    }
    async editItem(item) {
        this.editedItem = item;
        if (item.acao_id) {
            this.acaoSelected = [
                // @ts-ignore
                _.find(this.parceriaAcaoOptions(), (i) => i.id == item.acao_id).name,
                // @ts-ignore
                _.find(this.parceriaAcaoOptions(), (i) => i.id == item.acao_id),
            ];
        }
        this.dataAcao = item.data;
        this.descricao = item.descricao;
        this.colaboradores = [...item.parceria_acao_colaborador];
        this.horario_inicio = item.horario_inicio;
        this.horario_fim = item.horario_fim;
        this.dialog = true;
    }
    async marcarExecutada(item) {
        this.$swal({
            title: 'Confirma a execução da ação?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (await parceriaAcaoStore.updateStatusParceriaAcao({
                    id: item.id,
                    status_id: 2,
                })) {
                    this.initialize();
                }
            }
        });
    }
    async deleteItem(item) {
        this.$swal({
            title: 'Confirma a exclusão da ação?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await parceriaAcaoStore.deleteParceriaAcao(item.id);
                const editedIndex = this.items.indexOf(item);
                this.items.splice(editedIndex, 1);
            }
        });
    }
    async close() {
        this.dialog = false;
        this.reset();
    }
    async removeColaborador(colaborador) {
        const editedIndex = this.colaboradores.indexOf(colaborador);
        this.colaboradores.splice(editedIndex, 1);
    }
    async reset() {
        this.dataAcao = null;
        this.acaoSelected = [];
        this.descricao = '';
        this.colaboradores = [];
        this.entidadeSelected = null;
        // @ts-ignore
        this.$refs.searchEntidadeComponent.selectedEntidade = {};
        // @ts-ignore
        await this.$refs.dataAcao.$refs.observer.reset();
    }
    async save() {
        // @ts-ignore
        const isValidDate = await this.$refs.dataAcao.$refs.observer.validate();
        const isValid = await this.$refs.observer.validate();
        const isValidColaborador = this.colaboradores.length > 0;
        const isValidAcao = this.acaoSelected.length > 0;
        if (!isValidDate || !isValid || !isValidColaborador || !isValidAcao) {
            if (!isValidColaborador) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: ['Você precisa adicionar ao menos um colaborador!'],
                });
            }
            if (!isValidAcao) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: ['Você precisa escolher uma ação!'],
                });
            }
            return;
        }
        const obj = {
            // @ts-ignore
            id: this.editedItem?.id,
            parceria_id: this.propParceriaId,
            descricao: this.descricao,
            colaboradores: this.colaboradores,
            acao_id: this.acaoSelected[1].id,
            horario_inicio: this.horario_inicio,
            horario_fim: this.horario_fim,
            data: this.dataAcao,
        };
        await parceriaAcaoStore.updateParceriaAcao(obj);
        this.initialize();
        this.reset();
        this.close();
    }
    async mounted() {
        this.initialize();
    }
};
__decorate([
    Prop({ type: Number, default: null })
], ParceriaAcaoCrudComponent.prototype, "propParceriaId", void 0);
__decorate([
    Watch('dialog')
], ParceriaAcaoCrudComponent.prototype, "onDialog", null);
ParceriaAcaoCrudComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            DateFieldComponent,
            LogShowComponent,
            SaveButton,
            SearchEntidadeComponent,
        },
    })
], ParceriaAcaoCrudComponent);
export default ParceriaAcaoCrudComponent;
