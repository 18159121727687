import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import SaveButton from '@/components/SaveButton.vue';
import { parceriaCupomStore, parceriaStore, mainStore } from '@/store';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    },
});
let ParceriaCupomCreateComponent = class ParceriaCupomCreateComponent extends Vue {
    constructor() {
        super(...arguments);
        this.entityObject = {};
        this.parceriaCuponsNumeroIgual = [];
        this.headers = [
            { text: 'Nome', value: 'nome' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Idade', value: 'idade' },
            { text: 'E-mail', value: 'email' },
            { text: 'Tipo', value: 'interesse_id' },
            { text: 'Ações', value: 'actions' },
        ];
        this.headersRepetidos = [
            { text: 'Nome', value: 'nome' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Idade', value: 'idade' },
            { text: 'E-mail', value: 'email' },
            { text: 'Tipo', value: 'interesse_id' },
        ];
        this.items = [];
        this.parceriaSelected = {};
        this.parceriaSelectedErrors = [];
        this.paraQuemCursoErrors = [];
        this.loading = false;
        this.paraQuemCurso = 0;
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async deleteItem(id, index) {
        this.$swal({
            title: 'Confirma a exclusão do cupom?',
            text: 'Após a exclusão o cupom não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await parceriaCupomStore.deleteParceriaCupom(id);
                // @ts-ignore
                await parceriaCupomStore.getParceriaCupons();
                this.items.splice(index + 1, 1);
                this.loading = false;
            }
        });
    }
    async validateTelefone(telefone) {
        this.parceriaCuponsNumeroIgual =
            await parceriaCupomStore.getParceriaCupomByTelefone(telefone);
    }
    removeErrosParceria() {
        this.parceriaSelectedErrors = [];
        this.paraQuemCursoErrors = [];
    }
    async submit() {
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        const isValidParceria = Object.keys(this.parceriaSelected).length;
        const isValidParaQuemCurso = this.paraQuemCurso;
        if (!isValid || !isValidParceria || !isValidParaQuemCurso) {
            if (!isValidParceria) {
                this.parceriaSelectedErrors = ['Parceria deve ser preenchido!'];
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: this.parceriaSelectedErrors,
                });
            }
            if (!isValidParaQuemCurso) {
                this.paraQuemCursoErrors = ['Para quem é o curso deve ser preenchido!'];
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: this.paraQuemCursoErrors,
                });
            }
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.parceria_id = this.parceriaSelected.id;
        this.entityObject.status_id = 1;
        this.entityObject.para_quem_curso_id = this.paraQuemCurso;
        const result = await parceriaCupomStore.createParceriaCupom(this.entityObject);
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        if (!result) {
            return;
        }
        this.entityObject.id = result;
        const obj = JSON.parse(JSON.stringify(this.entityObject));
        this.items.push(obj);
        this.entityObject.nome = '';
        this.entityObject.telefone = '';
        this.entityObject.idade = '';
        this.entityObject.email = '';
        this.entityObject.id = null;
        this.entityObject.interesse_id = null;
        // @ts-ignore
        await this.$refs.observer.reset();
    }
    async mounted() {
        await parceriaStore.getParcerias();
        if (this.propParceriaId) {
            this.parceriaSelected = _.find(this.parcerias, (item) => item.id === this.propParceriaId);
        }
    }
};
__decorate([
    Prop({ type: Number, default: null })
], ParceriaCupomCreateComponent.prototype, "propParceriaId", void 0);
ParceriaCupomCreateComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            EntidadeLinkComponent,
        },
    })
], ParceriaCupomCreateComponent);
export default ParceriaCupomCreateComponent;
